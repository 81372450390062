define([], function() {

  

  var accordionContainer = function() {
    var comp = {};

    var _init = function(element) {
      comp.element = element;
      comp.accordions = comp.element.querySelectorAll('[data-accordion]');
      comp.last = null;

      for (var i = 0; i < comp.accordions.length; i++) {
        comp.accordions[i].collection = comp;
      }
    };

    var _trigger = function(accordion) {
      if (comp.last && comp.last !== accordion && comp.last.opened) {
        comp.last.toggle();
      }

      comp.last = accordion;
    };

    comp.init = _init;
    comp.trigger = _trigger;

    return comp;
  };

  return accordionContainer;
});
